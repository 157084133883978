/* src/styles/markdown.css */

.markdown-content {
  /* Add some general styles for the markdown content */
  font-size: 16px;
  line-height: 1.6;
}

.markdown-content p {
  margin-bottom: 1.5em; /* Increase space between paragraphs */
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.markdown-content ul,
.markdown-content ol {
  margin-bottom: 1.5em;
  padding-left: 2em;
}

.markdown-content li {
  margin-bottom: 0.5em;
}

.markdown-content a {
  color: #0066cc;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content blockquote {
  border-left: 4px solid #ccc;
  margin: 1.5em 0;
  padding-left: 1em;
  color: #666;
}

.markdown-content code {
  background-color: #f4f4f4;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
  color: #c7254e;
}

.markdown-content pre {
  background-color: #f4f4f4;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
  margin-bottom: 1.5em;
}

.markdown-content img {
  max-width: 100%;
  height: auto;
  margin: 1.5em 0;
}